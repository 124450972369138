var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("setup-table", {
    attrs: {
      title: `Funding Source`,
      items: _vm.items,
      headers: _vm.headers,
      loading: _vm.loading,
      clearItem: !_vm.showForm || !_vm.editedItem.id,
    },
    on: {
      createItem: function ($event) {
        return _vm.createItem()
      },
      editItem: _vm.editItem,
      deleteItems: _vm.deleteItems,
    },
    scopedSlots: _vm._u(
      [
        _vm.showForm
          ? {
              key: "itemForm",
              fn: function () {
                return [
                  _c("funding-source-form", {
                    ref: "fundingSourceForm",
                    attrs: {
                      value: _vm.editedItem,
                      budgetCodes: _vm.budgetCodes,
                      tripTypes: _vm.allActiveTripTypes,
                      users: _vm.users,
                    },
                    on: {
                      submit: _vm.saveItem,
                      closeForm: _vm.closeForm,
                      formChanges: _vm.formChanges,
                    },
                  }),
                ]
              },
              proxy: true,
            }
          : null,
        {
          key: `item.type`,
          fn: function ({ item }) {
            return [
              _c("p", { staticClass: "mb-0" }, [
                _vm._v(_vm._s(_vm.types[item.type - 1])),
              ]),
            ]
          },
        },
        {
          key: `item.incOnFinance`,
          fn: function ({ item }) {
            return [
              _c("v-icon", { attrs: { small: "" } }, [
                _vm._v(_vm._s(item.incOnFinance ? "mdi-check" : "mdi-close")),
              ]),
            ]
          },
        },
        {
          key: `item.locationSpecific`,
          fn: function ({ item }) {
            return [
              _c("v-icon", { attrs: { small: "" } }, [
                _vm._v(
                  _vm._s(item.locationSpecific ? "mdi-check" : "mdi-close")
                ),
              ]),
            ]
          },
        },
        {
          key: `item.active`,
          fn: function ({ item }) {
            return [
              _c("v-icon", { attrs: { small: "" } }, [
                _vm._v(_vm._s(item.active ? "mdi-check" : "mdi-close")),
              ]),
            ]
          },
        },
        {
          key: `item.budgetCode`,
          fn: function ({ item }) {
            return [
              _c(
                "div",
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                item.isBudgetCodeDeleted
                                  ? _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              small: "",
                                              left: "",
                                              color: "red",
                                            },
                                          },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" mdi-alert-circle ")]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      item.isBudgetCodeDeleted
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.removedBudgetCodeMessage)),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" " + _vm._s(item.budgetCode) + " "),
                ],
                1
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }