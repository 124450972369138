var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    { attrs: { readonly: _vm.value.id > 0 && !_vm.canEditSetupTables } },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "px-0", attrs: { cols: "12", md: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.cancel(false)
                        },
                      },
                    },
                    [_vm._v(" cancel ")]
                  ),
                  _vm.canEditSetupTables
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "float-right text-white",
                          attrs: {
                            color: "green",
                            disabled: _vm.$v.form.$invalid,
                          },
                          on: { click: _vm.onSubmit },
                        },
                        [_vm._v(" save ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-expansion-panels",
        { attrs: { accordion: "", mandatory: "" } },
        [
          _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header", [_vm._v("General")]),
              _c(
                "v-expansion-panel-content",
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "8" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.value.name,
                              label: "Name",
                              required: "",
                              "error-messages": _vm.handleErrors(
                                _vm.$v.form.name
                              ),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.name.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.name.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.name,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.name.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              value: _vm.value.active,
                              label: "Active",
                              required: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$v.form.active.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.active.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.form.active, "$model", $$v)
                              },
                              expression: "$v.form.active.$model",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "10" } },
                        [
                          _c("v-select", {
                            staticClass: "mb-0",
                            attrs: {
                              "menu-props": { bottom: true, offsetY: true },
                              label: "Funding Source Type",
                              value: _vm.value.type,
                              items: _vm.fsTypes,
                              "item-text": "name",
                              "item-value": "id",
                              outlined: "",
                              "error-messages": _vm.handleErrors(
                                _vm.$v.form.type
                              ),
                            },
                            on: { change: _vm.handleTypeChange },
                            model: {
                              value: _vm.$v.form.type.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.form.type, "$model", $$v)
                              },
                              expression: "$v.form.type.$model",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _vm.SINGLE_BUDGET_CODE_IDS.includes(
                        _vm.$v.form.type.$model
                      )
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12", md: "8" } },
                            [
                              _c("v-select", {
                                staticClass: "mb-0",
                                attrs: {
                                  "menu-props": { bottom: true, offsetY: true },
                                  label: "Budget Code",
                                  value: _vm.value.budgetCode,
                                  items: _vm.filteredBudgetCodes,
                                  "item-text": "description",
                                  "item-value": "id",
                                  outlined: "",
                                  clearable: "",
                                  "hide-details": "auto",
                                },
                                model: {
                                  value: _vm.$v.form.budgetCodeId.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.form.budgetCodeId,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression: "$v.form.budgetCodeId.$model",
                                },
                              }),
                              _c("div", { staticClass: "mt-2" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.getBudgetCodeName(
                                      _vm.$v.form.budgetCodeId.$model
                                    )
                                  )
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.$v.form.type.$model == 4
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12", md: "8" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  value: _vm.value.budgetCode,
                                  label: "Budget Code",
                                  required: "",
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.$v.form.budgetCode.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.$v.form.budgetCode.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.form.budgetCode,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression: "$v.form.budgetCode.$model",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.$v.form.type.$model ===
                      _vm.fundingSourceTypesMap.singleBudgetCodeAllotments
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12", md: "4" } },
                            [
                              _c("v-checkbox", {
                                attrs: {
                                  value: _vm.value.allotmentsUsed,
                                  label: "Allotments Used",
                                  required: "",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$v.form.allotmentsUsed.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.$v.form.allotmentsUsed.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.form.allotmentsUsed,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression: "$v.form.allotmentsUsed.$model",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.$v.form.type.$model == 4
                    ? _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "10" } },
                            [
                              _c("v-checkbox", {
                                attrs: {
                                  value: _vm.value.codeIsEditable,
                                  label: "Budget Code editable on Trip Request",
                                  required: "",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$v.form.codeIsEditable.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.$v.form.codeIsEditable.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.form.codeIsEditable,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression: "$v.form.codeIsEditable.$model",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "10" } },
                        [
                          _c("v-textarea", {
                            attrs: {
                              value: _vm.value.description,
                              "auto-grow": "",
                              outlined: "",
                              label: "Description",
                              rows: "2",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$v.form.allotmentsUsed.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.description.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.form.description, "$model", $$v)
                              },
                              expression: "$v.form.description.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "mb-0",
                          attrs: { cols: "12", md: "10" },
                        },
                        [
                          _c("user-select", {
                            attrs: {
                              value: _vm.value.approverId,
                              users: _vm.users,
                              label: "Approver",
                              noChips: true,
                            },
                            on: {
                              handleUserSelect: function ($event) {
                                return _vm.handleUserSelect(
                                  $event,
                                  "approverId"
                                )
                              },
                            },
                            model: {
                              value: _vm.$v.form.approverId.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.approverId,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.approverId.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "mb-0",
                          attrs: { cols: "12", md: "10" },
                        },
                        [
                          _c("user-select", {
                            attrs: {
                              value: _vm.value.payerId,
                              users: _vm.users,
                              label: "Payment Manager",
                              noChips: true,
                            },
                            on: {
                              handleUserSelect: function ($event) {
                                return _vm.handleUserSelect($event, "payerId")
                              },
                            },
                            model: {
                              value: _vm.$v.form.payerId.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.form.payerId, "$model", $$v)
                              },
                              expression: "$v.form.payerId.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "mb-0",
                          attrs: { cols: "12", md: "10" },
                        },
                        [
                          _c("v-combobox", {
                            attrs: {
                              value: _vm.value.notifyEmail,
                              items: _vm.userEmails,
                              label: "Notification of Use",
                              outlined: "",
                              "return-object": false,
                            },
                            model: {
                              value: _vm.$v.form.notifyEmail.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.form.notifyEmail, "$model", $$v)
                              },
                              expression: "$v.form.notifyEmail.$model",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              value: _vm.value.incOnFinance,
                              label: "Include on School Finance",
                              required: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$v.form.incOnFinance.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.incOnFinance.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.incOnFinance,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "$v.form.incOnFinance.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              value: _vm.value.locationSpecific,
                              label: "Location Specific",
                              required: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$v.form.locationSpecific.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.locationSpecific.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.locationSpecific,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "$v.form.locationSpecific.$model",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.$v.form.locationSpecific.$model
                    ? _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "10" } },
                            [
                              _c("v-select", {
                                staticClass: "mb-0",
                                attrs: {
                                  "menu-props": { bottom: true, offsetY: true },
                                  label: "Locations",
                                  value: _vm.value.locations,
                                  items: _vm.locations,
                                  "item-text": "name",
                                  "item-value": "id",
                                  outlined: "",
                                  multiple: "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "selection",
                                      fn: function ({ item, parent }) {
                                        return [
                                          _c(
                                            "v-chip",
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "pr-2" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.name) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-icon",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return parent.selectItem(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" $delete ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  191486017
                                ),
                                model: {
                                  value: _vm.$v.form.locations.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.form.locations,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression: "$v.form.locations.$model",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "10" } },
                        [
                          _c("v-select", {
                            staticClass: "mb-0",
                            attrs: {
                              "menu-props": { bottom: true, offsetY: true },
                              label: "Display on these Trips",
                              value: _vm.value.displayOnTripTypes,
                              items: _vm.tripTypes,
                              "item-text": "name",
                              "item-value": "id",
                              outlined: "",
                              multiple: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "prepend-item",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-list-item",
                                      {
                                        attrs: { ripple: "" },
                                        on: {
                                          mousedown: function ($event) {
                                            $event.preventDefault()
                                          },
                                          click: _vm.toggle,
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-action",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color:
                                                    _vm.displayOnTripTypes
                                                      ?.length > 0
                                                      ? "primary"
                                                      : "",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(_vm.icon) + " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(" Select All "),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-divider", { staticClass: "mt-2" }),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "selection",
                                fn: function ({ item, parent }) {
                                  return [
                                    _c(
                                      "v-chip",
                                      [
                                        _c("span", { staticClass: "pr-2" }, [
                                          _vm._v(" " + _vm._s(item.name) + " "),
                                        ]),
                                        _c(
                                          "v-icon",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return parent.selectItem(item)
                                              },
                                            },
                                          },
                                          [_vm._v(" $delete ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.displayOnTripTypes,
                              callback: function ($$v) {
                                _vm.displayOnTripTypes = $$v
                              },
                              expression: "displayOnTripTypes",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showBudgeting
            ? _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [_vm._v("Budgeting")]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("date-picker", {
                                attrs: { label: "Budget Start Date" },
                                model: {
                                  value: _vm.form.budget.startDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form.budget, "startDate", $$v)
                                  },
                                  expression: "form.budget.startDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("date-picker", {
                                attrs: { label: "Budget End Date" },
                                model: {
                                  value: _vm.form.budget.endDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form.budget, "endDate", $$v)
                                  },
                                  expression: "form.budget.endDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "4" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  value: _vm.value.budget.allotment,
                                  label: "Budget Allotment",
                                  type: "number",
                                  min: "0",
                                  "prepend-icon": "mdi-currency-usd",
                                },
                                model: {
                                  value: _vm.form.budget.allotment,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form.budget, "allotment", $$v)
                                  },
                                  expression: "form.budget.allotment",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c("v-col", { attrs: { cols: "12", md: "4" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "text-subtitle-2 font-weight-bold",
                              },
                              [_vm._v("Invoices Approved for Payment:")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "text-subtitle-2 font-weight-bold",
                              },
                              [_vm._v("Invoices Payment Sent:")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "text-subtitle-2 font-weight-bold",
                              },
                              [_vm._v("Invoices Paid:")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "text-subtitle-2 font-weight-bold",
                              },
                              [_vm._v("Balance:")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "text-subtitle-2 font-weight-bold",
                              },
                              [_vm._v("Invoices Pending:")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "text-subtitle-2 font-weight-bold",
                              },
                              [_vm._v("Est Driver Costs:")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "text-subtitle-2 font-weight-bold",
                              },
                              [_vm._v("Trips Without Reservations:")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "text-subtitle-2 font-weight-bold",
                              },
                              [_vm._v("Estimate Balance:")]
                            ),
                          ]),
                          _c("v-col", { attrs: { cols: "12", md: "8" } }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-subtitle-2 font-weight-normal",
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.value.budget.invoicesApproved)
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-subtitle-2 font-weight-normal",
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.value.budget.invoicesPaymentSent)
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-subtitle-2 font-weight-normal",
                              },
                              [_vm._v(_vm._s(_vm.value.budget.invoicesPaid))]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-subtitle-2 font-weight-normal",
                              },
                              [_vm._v(_vm._s(_vm.value.budget.balance))]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-subtitle-2 font-weight-normal",
                              },
                              [_vm._v(_vm._s(_vm.value.budget.invoicesPending))]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-subtitle-2 font-weight-normal",
                              },
                              [_vm._v(_vm._s(_vm.value.budget.estDriverCosts))]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-subtitle-2 font-weight-normal",
                              },
                              [_vm._v(_vm._s(_vm.value.budget.tripsNoRes))]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-subtitle-2 font-weight-normal",
                              },
                              [_vm._v(_vm._s(_vm.value.budget.estBalance))]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }